<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="3"
      >
        <statistics-card-with-images
          class="fill-height"
          :icon="ratingsOptions.icon"
          :avatar="ratingsOptions.avatar"
          :avatar-width="ratingsOptions.avatarWidth"
          :chip-color="ratingsOptions.chipColor"
          :chip-text="ratingsOptions.chipText"
          :statistics="totalAmountList"
          :stat-title="ratingsOptions.statTitle"
        ></statistics-card-with-images>
      </v-col>
      <v-col
        cols="12"
        sm="9"
      >
        <v-card class="fill-height">
          <v-card-title>Suchen</v-card-title>
          <v-card-text>
            <div class="d-flex align-center pb-5">
              <v-text-field
                v-model="filter.search"
                single-line
                dense
                outlined
                hide-details
                clearable
                placeholder="Kanal suchen"
                class="channel-list-search me-3"
              ></v-text-field>
              <v-select
                v-model="filter.angabeAktiv"
                :items="filterAngabeAktivOptions"
                item-text="key"
                item-value="value"
                label="Aktiv"
                outlined
                dense
                hide-details
                placeholder="Aktiv"
                class="channel-list-angabeAktiv me-3"
              ></v-select>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card id="channel-list">
          <v-card-text class="d-flex align-center flex-wrap pb-0">
            <div class="d-flex align-center pb-5">

              <router-link to="/kanal/erstellen/" >
                <v-btn
                  color="secondary"
                  outlined
                  class="me-3"
                >
                  <v-icon size="30" class="pr-2">{{icons.mdiWeb}}</v-icon>
                  <span>Kanal erstellen</span>
                </v-btn>
              </router-link>
            </div>
          </v-card-text>
        </v-card>
        <v-data-table
          :headers="headers"
          :items="listChannels"
          :options.sync="options"
          :server-items-length="totalAmountList"
          :items-per-page="options.itemsPerPage"
          :loading="loading"
          :search="filter.search"
          class="text-no-wrap"
          no-data-text="Keine Einträge vorhanden"
          no-results-text="Ihre Suche ergab keinen Treffer"
          :footer-props="{
            itemsPerPageText: 'Anzahl pro Seite',
            itemsPerPageAllText: 'Alle',
            pageText: '{0}-{1} von {2}',
            itemsPerPageOptions: [5,10,25,50,-1]
          }"
        >
          <template
            v-slot:top
          >
            <v-dialog
              v-model="dialogDelete"
              width="500px"
            >
              <v-card>
                <v-card-title
                  class="text-h5"
                >
                  Möchten Sie den Kanal <strong>{{ deleteItem.name }}</strong> wirklich löschen?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    @click="deleteChannelConfirm"
                  >
                    Löschen
                  </v-btn>
                  <v-btn
                    color="secondary"
                    outlined
                    @click="closeDelete"
                  >
                    Abbrechen
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="dialogEdit"
              width="500px"
            >
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editItem.name"
                          label="Name"
                          dense
                          hide-details
                          outlined
                          :rules="[nameRules]"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12">
                        <v-textarea
                          v-model="editItem.memo"
                          label="Memo"
                          auto-grow
                          dense
                          hide-details
                          outlined
                          rows="6"
                          row-height="15"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    :disabled="editValidationError"
                    @click="editChannelConfirm()"
                  >
                    Speichern
                  </v-btn>
                  <v-btn
                    color="secondary"
                    outlined
                    @click="closeEdit()"
                  >
                    Abbrechen
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>


            <v-dialog
              v-model="dialogIntegrationcode"
              width="700px"
            >
              <v-card>
                <v-card-title
                  class="text-h5"
                >
                  Integrationscode
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>


                      <v-col cols="12">
                        <p>
                          Um Web-Push-Benachrichtigungen erfolgreich einzurichten, führen Sie bitte die folgenden Schritte aus:
                        </p>

                        <ol>
                          <li class="mb-3">
                            <strong>Service Worker-Datei herunterladen:</strong>
                            Laden Sie die Datei <a :href="integration.serviceWorkerDownloadUrl" target="_blank" :download="integration.serviceWorkerFileName">serviceworker.js</a> herunter. Diese Datei ist der sogenannte "Service Worker", der die Benachrichtigungen steuert und sicherstellt, dass sie an Ihre Website-Besucher gesendet werden, auch wenn diese die Website aktuell nicht geöffnet haben.
                          </li>

                          <li class="mb-3">
                            <strong>Service Worker-Datei hochladen:</strong>
                            Legen Sie die heruntergeladene <code>serviceworker.js</code>-Datei im Stammverzeichnis Ihrer Website ab. Das Stammverzeichnis wird oft als <code>public_html</code> oder ähnlich bezeichnet. Dies ist erforderlich, um sicherzustellen, dass die Datei korrekt geladen wird und Browser-Sicherheitsrichtlinien (CORS) erfüllt sind.
                          </li>

                          <li class="mb-3">
                            <strong>Integrationscode einfügen:</strong>
                            Kopieren Sie den JavaScript-Code von unten und fügen Sie ihn auf den Seiten Ihrer Website ein, auf denen Sie Web-Push-Benachrichtigungen aktivieren möchten. Dies sollte idealerweise im <code>&lt;head&gt;</code>- oder <code>&lt;body&gt;</code>-Bereich Ihrer Website geschehen.

                            <v-textarea
                              v-model="integration.code"
                              label="JavaScript-Code zur Integration"
                              dense
                              hide-details
                              outlined
                              rows="7"
                              row-height="12"
                              class="mt-5 mb-5"
                            ></v-textarea>
                          </li>
                        </ol>

                        <p>
                          Sobald diese Schritte abgeschlossen sind, sind die Web-Push-Benachrichtigungen auf Ihrer Website aktiviert. Ihre Besucher können nun Nachrichten empfangen, selbst wenn sie Ihre Seite gerade nicht geöffnet haben.
                        </p>

                        <p>
                          Sollten Sie Fragen oder Unterstützung benötigen, stehen wir Ihnen gerne zur Verfügung.
                        </p>
                      </v-col>


                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-btn
                    color="secondary"
                    outlined
                    @click="closeIntegrationCode"
                  >
                    OK
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>

          <template #[`item.idNipKanal`]="{item}">
            #{{ item.idNipKanal }}
          </template>
          <template #[`item.name`]="{item}">
            {{ item.name }}
          </template>
          <template #[`item.domain`]="{item}">
            {{ item.domain }}
          </template>
          <template #[`item.angabeAktiv`]="{item}">
            <v-chip
              @click="setChannelState( item.idNipKanal, !item.angabeAktiv )"
              small
              :color="item.angabeAktiv == 1 ? 'success':'secondary'"
              :class="`v-chip-light-bg ${item.angabeAktiv == 1 ? 'success':'secondary'}--text font-weight-semibold`"
            >
              {{ item.angabeAktiv == 1 ? 'Ja':'Nein' }}
            </v-chip>
          </template>

          <template #[`item.actions`]="{item}">
            <div class="">


              <v-tooltip
                bottom
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="generateIntegrationCode( item )"
                  >
                    <v-icon size="18">
                      {{ icons.mdiCodeBraces }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Integrationscode anzeigen</span>
              </v-tooltip>

              <v-tooltip
                bottom
              >
                <template #activator="{ on, attrs }">
                  <router-link :to="'/kanal/editieren/'+item.idNipKanal" >
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="18">
                        {{ icons.mdiPencilOutline }}
                      </v-icon>
                    </v-btn>
                  </router-link>
                </template>
                <span>Bearbeiten</span>
              </v-tooltip>

              <v-tooltip
                bottom
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteChannel( item )"
                  >
                    <v-icon size="18">
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Löschen</span>
              </v-tooltip>

            </div>
          </template>

        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mdiDeleteOutline,
  mdiCodeBraces,
  mdiPencilOutline,
  mdiCheckCircleOutline,
  mdiAlertCircleOutline, mdiWeb,
  mdiPlus, mdiViewDashboardVariantOutline,
} from '@mdi/js'
import axios from "axios";
import {getCurrentInstance, ref} from "@vue/composition-api";
import StatisticsCardWithImages from "@core/components/statistics-card/StatisticsCardWithImages";

export default {
  name: 'channel-list',
  components:{
    StatisticsCardWithImages
  },
  setup() {
    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = 4000;

    const ratingsOptions = {
      statTitle: 'Kanäle',
      statistics: '13k',
      change: '+38%',
      chipText: 'Gesamt',
      chipColor: 'error',
      avatar: require('@/assets/images/avatars/9.png'),
      avatarWidth: '111',
      icon:mdiWeb
    }

    return {
      vm,
      ratingsOptions
    }
  },
  data() {
    return {
      actions: ['Delete', 'Edit'],
      icons: {
        mdiDeleteOutline,
        mdiCodeBraces,
        mdiPencilOutline,
        mdiCheckCircleOutline,
        mdiAlertCircleOutline,
        mdiWeb      ,
        mdiPlus,
      },
      filter: { search: '', angabeAktiv : 'all', angabeArchiviert : 'all', },
      loading: false,
      totalAmountList: 0,
      filterAngabeAktivOptions: [
        { value: "all", key: 'Alle' },
        { value: "1", key: 'Ja' },
        { value: "0", key: 'Nein' },
      ],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['idNipKanal'],
        sortDesc: [true],
      },
      headers: [
        {
          text: '#ID',
          align: 'start',
          value: 'idNipKanal',
        },
        {text: 'Name', value: 'name'},
        {text: 'Domain', value: 'domain'},
        {text: 'Aktiv', value: 'angabeAktiv'},
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false,
        },
      ],
      listChannels: [],
      dialogIntegrationcode: false,
      dialogDelete: false,
      dialogEdit: false,
      deleteItem: {
        idNipKanal: 0,
        name: '',
      },
      integration: {
        code: '<script src="https://aimpush.nitramit.de/integration.js"><\/script>'
      },
      editIndex: -1,
      editValid : false,
      editItem: {
        idNipKanal: 0,
        name: '',
        memo: '',
      },
      defaultItem: {
        idNipKanal: 0,
        name: '',
        memo: '',
      },
      editValidationError : true,
    }
  },
  mounted() {
    // this.fetchChannels()
  },
  watch: {
    options: {
      handler() {
        this.fetchChannels()
      },
    },
    filter: {
      handler() {
        this.fetchChannels()
      },
      deep: true,
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    dialogIntegrationcode (val) {
      val || this.closeIntegrationCode()
    },
    dialogEdit (val) {
      val || this.closeEdit()
    },
  },
  computed: {
    formTitle () {
      return this.editIndex === -1 ? 'Kanal hinzufügen' : 'Kanal bearbeiten'
    },
  },
  methods: {
    fetchChannels() {
      this.loading = true

      axios.post('api/kanal/', {
        aktion: 'showOverviewChannels',
        filter: this.filter,
        options: encodeURIComponent(JSON.stringify(this.options)),
      })
        .then((response) => {
          if (response.data.valid === true) {
            this.listChannels = response.data.channels || []
            this.totalAmountList = parseInt(response.data.total) || 0
            this.loading = false
          } else {
            console.log(response.data.errors)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    setChannelState(idNipKanal, state) {
      axios.post('api/kanal/', {
        aktion: 'changeChannelState',
        idNipKanal: idNipKanal,
        newState: state,
      })
        .then((response) => {
          this.vm.$toastr.s(response.data.message.title, response.data.message.text);
          this.fetchChannels()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    generateIntegrationCode (item) {
      this.dialogIntegrationcode = true

      axios.post('api/kanal/', {
        aktion: 'generateIntegrationCodeForChannel',
        idNipKanal: item.idNipKanal,
      })
        .then((response) => {
          console.log(response.data)
          this.integration = response.data.integration
        })
        .catch((error) => {
          console.log(error)
        })


    },
    closeIntegrationCode () {
      this.dialogIntegrationcode = false
    },
    deleteChannel (item) {
      this.dialogDelete = true
      this.deleteItem = Object.assign({}, item)
    },
    deleteChannelConfirm () {
      axios.post('api/kanal/', {
        aktion: 'deleteChannel',
        idNipKanal: this.deleteItem.idNipKanal,
      })
        .then((response) => {
          this.vm.$toastr.s(response.data.message.title, response.data.message.text);
          this.fetchChannels()
        })
        .catch((error) => {
          console.log(error)
        })

      this.closeDelete()
    },
    closeDelete () {
      this.dialogDelete = false
    },
    editChannel (item) {
      this.editValidationError = false
      this.dialogEdit = true
      if(item) {
        this.editIndex = this.listChannels.indexOf(item)
        this.editItem = Object.assign({}, item)
      }
    },
    nameRules (v) {
      this.editValidationError = true
      if(!v) {
        return 'Bitte füllen Sie dieses Feld aus';
      }
      else if((v || '').length < 2) {
        return 'Der Name muss mindetens 2 Zeichen besitzen.';
      }
      else if((v || '').length > 45) {
        return 'Der Name kann maximal 45 Zeichen lang sein.';
      }

      this.editValidationError = false
      return true
    },
    editChannelConfirm () {
      axios.post('api/kanal/', {
        aktion: this.editIndex === -1 ? 'createChannel' : 'editChannel',
        data: this.editItem,
      })
        .then((response) => {
            if (response.data.valid === true) {
              this.vm.$toastr.s(response.data.message.title, response.data.message.text);
              this.fetchChannels()
            }
            else {
              response.data.errors.forEach(item=>{
                let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
                this.vm.$toastr.e(item.errorText, item.errorTitle+suffix);
              })
            }
          this.closeEdit()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    closeEdit () {
      this.$nextTick(() => {
        this.dialogEdit = false
        this.editIndex = -1
        this.editItem = this.defaultItem
      })
    },


  }
}
</script>

<style lang="scss" scoped>
.v-card__title {
  word-break: normal;
}
#channel-list {
  .channel-list-search {
    max-width: 10.625rem;
  }
  .channel-list-angabeAktiv {
    max-width: 11.3rem;
  }
  .channel-list-angabeArchiviert {
    max-width: 11.3rem;
  }
}
</style>
